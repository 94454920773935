import { useQueries } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import { keyBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

import { restaurantsKeys, useConceptsConfigurationQuery } from '@/api/gateway-click-collect';
import {
  CurrencyCodeEnum,
  Menu,
  ModelConfiguration,
  OpeningHours,
} from '@/api/gateway-click-collect/restaurants/types';
import { useTasterApis } from '@/api/TasterApiContext';
import { MenuInfo } from '@/api/types';
import { InactiveUserModal } from '@/components/InactiveUserModal/InactiveUserModal';
import { useRestaurantsList } from '@/hooks/onSite/useRestaurantsList';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';
import { useRouteMatch } from '@/hooks/useRouteMatch';

export interface RestaurantWithIntructions extends MenuInfo {
  kitchenInstructions: string;
  currencyCode: string;
  openingHours: OpeningHours;
}

export const useKioskDataLayout = () =>
  useOutletContext<{
    areRestaurantsOnError: boolean;
    isLoading: boolean;
    conceptConfigs: ModelConfiguration;
    currencyCode: CurrencyCodeEnum;
    kitchenLabel: string;
    menus: Record<string, Menu>;
    restaurantsList: RestaurantWithIntructions[];
    outOfStocks: Record<string, { restaurantPlatformId: string; oos: string[] }>;
  }>();

const FIVE_MINUTES_REFETCH_INTERVAL = 300000;

export const FetchKioskDataLayout = () => {
  const { hasPairedTerminal, isKioskUser } = useOnSiteInfo();

  const { data: conceptConfigs, isLoading: areConceptsLoading } = useConceptsConfigurationQuery({
    options: {
      enabled: isKioskUser,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  });

  const conceptConfigsData = useMemo<ModelConfiguration>(() => {
    if (!conceptConfigs) return { concepts: [] };

    return conceptConfigs;
  }, [conceptConfigs]);

  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');

  const { areRestaurantsLoading, areRestaurantsOnError, restaurantsList, kitchenLabel } = useRestaurantsList({
    locationUuid,
    hasPairedTerminal,
    conceptConfigs: conceptConfigsData,
  });

  const restaurantIds = useMemo(
    () => restaurantsList.map(({ restaurantPlatformId }) => restaurantPlatformId),
    [restaurantsList]
  );

  const menuList = useQueries({
    queries: restaurantIds.map((restaurantPlatformId) => ({
      enabled: isKioskUser,
      queryKey: restaurantsKeys.getMenu({ restaurantPlatformId }),
      queryFn: () => restaurantsApi.getRestaurantMenu({ restaurantPlatformId }),
    })),
  });

  const outOfStockList = useQueries({
    queries: restaurantIds.map((restaurantPlatformId) => ({
      enabled: isKioskUser,
      queryKey: restaurantsKeys.getOutOfStocks({ restaurantPlatformId }),
      queryFn: async () => ({
        oos: await restaurantsApi.getRestaurantOutOfStocks({ restaurantPlatformId }),
        restaurantPlatformId,
      }),
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
    })),
  });

  const menus = keyBy(
    menuList.map(({ data }) => data),
    'restaurantPlatformId'
  );

  const outOfStocks = keyBy(
    outOfStockList.map(({ data }) => data),
    'restaurantPlatformId'
  );

  const [, setCurrencyCode] = useLocalStorage('CURRENCY_CODE', '');

  const currencyCode = restaurantsList[0]?.currencyCode;

  useEffect(() => {
    if (currencyCode?.length) setCurrencyCode(currencyCode);
  }, [currencyCode, setCurrencyCode]);

  const areMenusLoading = menuList.some(({ isLoading }) => isLoading);

  const isLoading = (isKioskUser && (areMenusLoading || areConceptsLoading)) || areRestaurantsLoading;

  const isOnTerminalRequiredPage = !!useRouteMatch([
    '/onSite/menu/:restaurantPlatformId',
    '/onSite/menu/:restaurantPlatformId/categories/:categoryUuid',
    '/onSite/:locationUuid',
  ]);

  const isOnOrderingPage = !!useRouteMatch([
    '/onSite/menu/:restaurantPlatformId',
    '/onSite/menu/:restaurantPlatformId/categories/:categoryUuid',
  ]);

  if (isOnTerminalRequiredPage && !hasPairedTerminal) return <Navigate to="/onSite/terminalDisconnected" />;

  return (
    <>
      {isOnOrderingPage && isKioskUser && <InactiveUserModal locationUuid={locationUuid} />}
      <Outlet
        context={{
          areRestaurantsOnError,
          conceptConfigs,
          currencyCode,
          kitchenLabel,
          isLoading,
          menus,
          outOfStocks,
          restaurantsList,
        }}
      />
    </>
  );
};
