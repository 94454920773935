import { CardProps } from '@chakra-ui/card/dist/card';
import { MoonIcon } from '@chakra-ui/icons';
import {
  Box,
  Card,
  CardBody as ChakraCardBody,
  Heading,
  Icon,
  Image,
  Stack,
  SystemStyleObject,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import type { MenuInfo, RestaurantPlatform } from '@/api/types';
import { PlatformsDeliveryModal } from '@/components/PlatformsDeliveryModal';
import { OpeningHoursLabel } from '@/components/RestaurantCard/OpeningHoursLabel';
import { OrderButton } from '@/components/RestaurantCard/OrderButton';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';
import useOpeningHours from '@/hooks/useOpeningHours';

type RestaurantCardProps = CardProps & {
  compact?: boolean;
  locationUuid?: string;
  isDelivery?: boolean;
  restaurantPlatforms?: RestaurantPlatform[];
  menuInfo: MenuInfo;
};

const Overlay = ({ children, isOpen = false }: PropsWithChildren<{ isOpen?: boolean }>) => {
  return (
    <Stack
      alignItems="center"
      background={
        isOpen ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)' : 'rgba(0, 0, 0, 0.6)'
      }
      bottom="0"
      color="white"
      display="flex"
      justifyContent="center"
      left="0"
      pos="absolute"
      px={2}
      py={1}
      right="0"
      top="0"
    >
      {children}
    </Stack>
  );
};

const CardBody = ({
  children,
  compact = false,
  sx = {},
}: PropsWithChildren<{ compact?: boolean; sx?: SystemStyleObject }>) => {
  if (compact) {
    return (
      <ChakraCardBody
        align="stretch"
        as={VStack}
        flex={1}
        gap={2}
        justify="space-between"
        paddingBlock={3}
        paddingX={4}
        sx={sx}
      >
        {children}
      </ChakraCardBody>
    );
  }

  return (
    <ChakraCardBody
      alignItems="baseline"
      display="flex"
      flex={1}
      flexDirection="row"
      flexWrap="wrap"
      gap={2}
      justifyContent="stretch"
      paddingBlock={3}
      sx={sx}
      paddingX={4}
    >
      {children}
    </ChakraCardBody>
  );
};

export const RestaurantCard = ({
  compact: compactProp = false,
  isDelivery = false,
  menuInfo,
  restaurantPlatforms,
  ...cardProps
}: RestaurantCardProps) => {
  const { hasPairedTerminal, isKioskUser } = useOnSiteInfo();

  const navigate = useNavigate();
  const { kitchenLabel, restaurantPlatformId, menuPicture, label, isOpen, shifts } = menuInfo;
  const { isOpenNow, isOpenToday, openingLabel } = useOpeningHours(shifts, menuInfo.opensAt);
  const [isDeliveryOpen, setIsDeliveryOpen] = useBoolean(false);

  const compact = !!isDelivery || compactProp;

  const handleClick = () => {
    if (isDelivery) {
      setIsDeliveryOpen.on();
    } else if (isKioskUser && hasPairedTerminal) {
      navigate(`/onSite/menu/${restaurantPlatformId}`);
    } else {
      hasPairedTerminal ? navigate(`/onSite/menu/${restaurantPlatformId}`) : navigate(`/menu/${restaurantPlatformId}`);
    }
  };

  const isRestaurantCardDisabled = !menuInfo?.isOpen && isKioskUser && hasPairedTerminal;

  return (
    <Card
      as="article"
      bg="white"
      border="1px solid #EAECF0"
      borderRadius="lg"
      boxShadow="0px 0px 2px rgba(55, 19, 23, 0.08)"
      disabled={isRestaurantCardDisabled}
      display="flex"
      flexDirection="column"
      onClick={() => {
        if (!isRestaurantCardDisabled) handleClick();
      }}
      overflow="hidden"
      {...cardProps}
    >
      <Box height="150px" pos="relative" width="100%">
        <Image objectFit="cover" height="100%" width="100%" src={menuPicture} alt={label} />
        <Overlay isOpen={isOpen}>
          {compact && (
            <OpeningHoursLabel isOpenNow={isOpenNow} openingLabel={openingLabel} left={2} pos="absolute" top={1} />
          )}
          {!isOpen && (
            <>
              <Icon as={MoonIcon} boxSize={6} />
              <Text fontSize="md" fontWeight={500}>
                <Trans>Closed</Trans>
              </Text>
            </>
          )}
        </Overlay>
      </Box>
      {hasPairedTerminal ? (
        <Stack padding={3} gap={2}>
          <Heading as="h3" fontWeight="700" fontSize="md">
            {menuInfo.label}
          </Heading>
          <OrderButton isDisabled={isRestaurantCardDisabled} onClick={handleClick} isOnSite={true} isOpen={isOpen} />
        </Stack>
      ) : (
        <CardBody compact={compact}>
          <VStack align="stretch" flex={compact ? 0 : '9999 0 150px'}>
            <Heading as="h3" fontWeight="700" fontSize="md">
              {menuInfo.label}
            </Heading>
            {!compact && <OpeningHoursLabel isOpenNow={isOpenNow} openingLabel={openingLabel} />}
          </VStack>
          <OrderButton
            flex={compact ? '0 0 auto' : 1}
            hasPlatforms={!!restaurantPlatforms}
            isDelivery={isDelivery}
            isOpen={isOpen}
            isOpenToday={isOpenToday}
            minWidth="fit-content"
            isDisabled={isRestaurantCardDisabled}
            onClick={handleClick}
          />
          {isDelivery && !!restaurantPlatforms && (
            <PlatformsDeliveryModal
              isOpen={isDeliveryOpen}
              kitchenLabel={kitchenLabel}
              onClose={setIsDeliveryOpen.off}
              restaurantPlatforms={restaurantPlatforms}
            />
          )}
        </CardBody>
      )}
    </Card>
  );
};
