import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import ItemPicturePlageHolder from '@/assets/img/kiosk_item_placeholder.svg';
import Card from '@/components/Card';

interface KioskMenuCardProps {
  onClick?: () => void;
  label: string;
  pictureUrl?: string;
}

export const KioskMenuCard = ({ onClick, label, pictureUrl, children }: PropsWithChildren<KioskMenuCardProps>) => {
  return (
    <Box
      sx={{
        '&:active': {
          border: onClick ? '6px solid #333333' : 'unset',
        },
        height: '100%',
        border: onClick ? '6px solid transparent' : 'unset',
        position: 'relative',
        transition: 'border 0.3s',
        borderRadius: 24,
      }}
    >
      <Card
        as={onClick ? 'button' : 'div'}
        aria-label={onClick ? label : undefined}
        height="100%"
        width="100%"
        border="1px solid"
        borderColor="gray.border"
        borderRadius={16}
        boxShadow="0px 4px 16px 0px #0000001A"
        onClick={onClick}
        p={0}
      >
        <Stack height="100%" justifyContent="center" borderRadius={16}>
          <Image
            objectFit="cover"
            borderRadius="16px 16px 0px 0px"
            src={pictureUrl ?? ItemPicturePlageHolder}
            alt={label}
          />
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            p={3}
            flexDirection="column"
            height="inherit"
            justifyContent="center"
          >
            <Text
              marginBottom={0}
              fontSize={{ base: '20px', xl: '24px' }}
              color="gray.700"
              textAlign="center"
              fontWeight={700}
            >
              {label}
            </Text>
            {children}
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};
