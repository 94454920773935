import { Button, Grid, IconButton, Image, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

import ArrowLeft from '@/assets/img/arrow-left.svg';
import useFormatPrice from '@/hooks/useFormatPrice';

export interface FooterProps {
  currencyCode: string;
  hasOutOfStocks?: boolean;
  isError: boolean;
  isLoading: boolean;
  onPaymentClick: MouseEventHandler<HTMLButtonElement>;
  price?: number;
  restaurantPlatformId: string;
}

export const Footer = ({
  currencyCode,
  hasOutOfStocks = false,
  isError,
  isLoading,
  onPaymentClick,
  price,
  restaurantPlatformId,
}: FooterProps) => {
  const { formatPrice } = useFormatPrice();
  const formattedPrice = formatPrice(price ?? 0, currencyCode, { maximumFractionDigits: 2 });

  return (
    <Grid templateColumns="repeat(2, auto)" justifyContent="space-between" gap={8}>
      <Button
        as={Link}
        to={`/onSite/menu/${restaurantPlatformId}`}
        backgroundColor="white"
        colorScheme="gray"
        variant="outline"
        width="100%"
        textTransform="uppercase"
        paddingY={6}
        height="fit-content"
        borderRadius="lg"
        gridColumnStart={-1}
        gridColumnEnd={1}
      >
        <Text fontSize="2xl" fontWeight={700}>
          + <Trans>Add more items</Trans>
        </Text>
      </Button>
      <Text fontSize="4xl" fontWeight={700} paddingLeft={8} textTransform="uppercase">
        <Trans>Total</Trans>
      </Text>
      <Text fontSize="4xl" fontWeight={700} paddingRight={8}>
        {formattedPrice}
      </Text>
      <Grid
        gridTemplateColumns="minmax(0, 82px) 1fr"
        backgroundColor="white"
        borderTop="1px solid #D9D9D9"
        boxShadow="0px -4px 16px 0px #0000001A"
        paddingX={6}
        paddingY={4}
        alignItems="center"
        justifyContent="space-between"
        columnGap={8}
        rowGap={2}
        gridColumn="1 / -1"
      >
        <IconButton
          aria-label={t`Go back`}
          as={Link}
          colorScheme="gray"
          to={`/onSite/menu/${restaurantPlatformId}`}
          variant="outline"
          icon={<Image src={ArrowLeft} width={30} />}
          sx={{
            borderColor: 'gray.300',
            height: '82px',
            maxWidth: '82px',
            width: '100%',
          }}
        />
        <Button
          isLoading={isLoading}
          loadingText={
            <Text fontSize="3xl">
              <Trans>Pay</Trans>
            </Text>
          }
          isDisabled={isError}
          colorScheme="green"
          sx={{
            height: '82px',
            maxWidth: '400px',
            width: '100%',
          }}
          justifySelf="end"
          onClick={onPaymentClick}
        >
          <Text fontSize="3xl" fontWeight={700} textTransform="uppercase">
            <Trans>Pay</Trans>
          </Text>
        </Button>
        {hasOutOfStocks && (
          <Text fontSize="md" fontWeight="700" gridColumn="1 / -1" color="gray.600" justifySelf="end">
            <Trans>To continue, remove unavailable items</Trans>
          </Text>
        )}
      </Grid>
    </Grid>
  );
};
