import { useMemo } from 'react';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

import { ConceptInformation } from '@/api/gateway-click-collect';
import { Menu, OpeningHours, Restaurant } from '@/api/gateway-click-collect/restaurants/types';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { useKioskDataLayout } from './FetchKioskDataLayout';

export const useRestaurantKioskDataLayout = () =>
  useOutletContext<{
    currencyCode: string;
    openingHours: OpeningHours;
    menu: Menu;
    isLoading: boolean;
    outOfStocks: string[];
    restaurant: Restaurant;
    conceptConfig: ConceptInformation;
  }>();

export const SelectRestaurantDataLayout = () => {
  const {
    menus,
    outOfStocks: locationOutOfStocks,
    currencyCode,
    restaurantsList,
    conceptConfigs,
    isLoading,
  } = useKioskDataLayout();

  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };

  const restaurant = useMemo(() => {
    return restaurantsList.find((restaurant) => restaurant.restaurantPlatformId === restaurantPlatformId);
  }, [restaurantsList, restaurantPlatformId]);

  const openingHours = useMemo(() => {
    return restaurantsList.find((restaurant) => restaurant.restaurantPlatformId === restaurantPlatformId)?.openingHours;
  }, [restaurantsList, restaurantPlatformId]);

  const outOfStocks = useMemo(
    () => locationOutOfStocks[restaurantPlatformId]?.oos,
    [locationOutOfStocks, restaurantPlatformId]
  );

  const menu = useMemo(() => {
    const currentMenu = menus[restaurantPlatformId];

    if (!currentMenu?.items?.length) return;

    return currentMenu;
  }, [menus, restaurantPlatformId]);

  const conceptConfig = conceptConfigs?.concepts.find(({ uuid }) => uuid === menu?.conceptUuid);

  const { isKioskUser } = useOnSiteInfo();

  const isRestaurantDataOnError = useMemo(() => {
    if (isLoading || !isKioskUser) return;

    return !menu || !outOfStocks || !openingHours || !currencyCode;
  }, [currencyCode, isLoading, menu, openingHours, outOfStocks, isKioskUser]);

  if (isRestaurantDataOnError) return <Navigate to={'/onSite/notFound'} />;

  return (
    <Outlet
      context={{
        openingHours,
        menu,
        conceptConfig,
        outOfStocks,
        isLoading,
        currencyCode,
        restaurant,
        conceptConfigs,
      }}
    />
  );
};
