import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    breapoints: {
      sm: '431px',
      '2xl': '1366px',
      '3xl': '1536px',
    },
    components: {
      Button: {
        baseStyle: {
          fontWeight: 500,
          _disabled: {
            background: '#F6F6F6',
            color: '#B0B2B5',
          },
          _hover: {
            _disabled: {
              background: '#F6F6F6',
            },
          },
        },
        variants: {
          secondary: {
            background: '#EEEEEE',
            color: 'black.500',
            _hover: {
              background: 'blackAlpha.200',
            },
            _active: {
              background: 'blackAlpha.300',
            },
          },
        },
        sizes: {
          lg: {
            fontWeight: '500',
          },
          xl: {
            fontSize: '2xl',
            fontWeight: 700,
            h: '80px',
            px: '28px',
            textTransform: 'uppercase',
          },
        },
      },
      Link: {
        baseStyle: {
          fontWeight: 600,
        },
      },
      Input: {
        sizes: {
          md: {
            field: {
              height: 46,
            },
          },
        },
        variants: {
          outline: {
            field: {
              border: '1px solid',
              borderRadius: 'md',
              _focusVisible: {
                zIndex: 1,
                borderColor: '#000000',
                boxShadow: `0 0 0 1px #000000`,
              },
              _hover: {
                borderColor: '#000000',
              },
            },
          },
        },
      },
      Progress: {
        baseStyle: {
          filledTrack: {
            bg: '#D0D5DD',
          },
        },
      },
    },
    styles: {
      global: {
        'html, body': {
          backgroundColor: 'gray.50',
          height: '100%',
        },
      },
    },
    fonts: {
      body: "'DM Sans Variable', sans-serif",
      heading: "'DM Sans Variable', sans-serif",
    },
    colors: {
      black: {
        500: '#000000',
      },
      primary: {
        300: '#C4C4C4',
        700: '#434343',
      },
      red: {
        500: '#E50000',
        600: '#D92D20',
        700: '#B42318',
        required: '#D6331A',
        error: {
          300: '#FDA29B',
          25: '#FFFBFA',
          main: '#B00020',
          600: '#D92D20',
          700: '#B42318',
        },
        warning: {
          25: '#FFFCF5',
          300: '#FEC84B',
          600: '#DC6803',
          700: '#B54708',
        },
      },
      green: {
        success: {
          25: '#F6FEF9',
          300: '#6CE9A6',
          500: '#12B76A',
          600: '#039855',
          700: '#027A48',
          main: '#108452',
          icon: '#4CAF50',
        },
      },
      gray: {
        300: '#D0D5DD',
        600: '#475467',
        700: '#344054',
        border: '#D9D9D9',
        disabled: '#74767B',
        icons: '#757575',
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'black' })
);

export default theme;
