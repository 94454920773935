import { Box, Button, Image } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import HomeIcon from '@/assets/img/house.svg';
import { Cart } from '@/types';

import { KioskAbandonCartButton } from './KioskAbandonCartButton';
import { KioskCartButton } from './KioskCartButton';

export interface MenuItemsPageFooterProps {
  cart: Cart;
  onCartClick: () => void;
  restaurantPlatformId: string;
}

export const MenuItemsPageFooter = ({ cart, onCartClick, restaurantPlatformId }: MenuItemsPageFooterProps) => {
  const itemsQuantity = cart.items.reduce((quantityAcc, item) => quantityAcc + item.quantity, 0);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        flexWrap: 'wrap',
        width: '100%',
        gap: 2,
        zIndex: 2,
        flexDirection: 'row',
        height: { md: '111px' },
        alignItems: 'center',
        justifyContent: itemsQuantity ? 'space-between' : 'center',
        p: 4,
        background: 'white',
        boxShadow: '0px -4px 16px 0px #0000001A',
      }}
    >
      {!!itemsQuantity && <KioskAbandonCartButton />}
      <Button
        as={Link}
        color="gray.700"
        textTransform="uppercase"
        height={{ base: '40px', md: '80px' }}
        variant="outline"
        border="1px solid #D0D5DD"
        boxShadow="0px 1px 2px 0px #1018280D"
        leftIcon={<Image src={HomeIcon} />}
        fontSize={{ base: 16, md: 28 }}
        fontWeight={700}
        to={`/onSite/menu/${restaurantPlatformId}`}
      >
        <Trans>Home</Trans>
      </Button>
      {!!itemsQuantity && (
        <KioskCartButton
          itemsQuantity={itemsQuantity}
          to={`/onSite/menu/${restaurantPlatformId}/cart`}
          onClick={onCartClick}
        />
      )}
    </Box>
  );
};
