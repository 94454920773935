import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ReactNode } from 'react';
import { MdRefresh } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import MoonLogo from '@/assets/img/moon.svg';
import TasterLogo from '@/assets/img/taster-logo-black.svg';
import { ErrorScreen } from '@/components/ErrorScreen';
import PageLoader from '@/components/PageLoader';
import { SettingsAccessButton } from '@/components/PairingTerminal/SettingsAccessButton';
import { TouchToStartModal } from '@/components/TouchToStartModal';
import { useKioskDataContext } from '@/contexts/KioskDataProvider';
import { useOnSiteSession } from '@/contexts/onSiteSession';
import dataLayer from '@/helpers/dataLayer.helpers';

import { RestaurantCard } from './RestaurantCard';
import { formatRestaurants } from './RestaurantsPage.helpers';

interface LayoutProps {
  children: ReactNode;
  title: ReactNode;
}

const Layout = ({ children, title }: LayoutProps) => {
  return (
    <Stack height="100%" spacing={0}>
      <Box
        as="header"
        display="grid"
        gridTemplateColumns="1fr auto 1fr"
        borderBottom="1px"
        borderColor="#D9D9D9"
        boxShadow="md"
        paddingX={2}
        paddingY={6}
      >
        <Heading as="h1" textAlign="center" size="lg" marginX="auto" gridColumnStart={2}>
          {title}
        </Heading>
        <SettingsAccessButton sx={{ justifySelf: 'end' }} />
      </Box>
      {children}
    </Stack>
  );
};

export const RestaurantsPage = () => {
  const location = useLocation();
  const { startOnSiteSession } = useOnSiteSession();
  const { conceptsInformation, restaurants, seedRestaurantCache, isError, isLoading } = useKioskDataContext();
  const [kitchenLabel] = useLocalStorage('KITCHEN_LABEL', '');
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');

  const formattedRestaurants = formatRestaurants({ conceptsInformation, restaurants });

  if (formattedRestaurants?.length) {
    return (
      <Layout title={<Trans>Choose a brand to see the menu and order</Trans>}>
        <Box overflow="auto">
          <Box
            marginX={{ base: 0, lg: '15%' }}
            display="grid"
            justifyContent="center"
            gridTemplateColumns={{
              base: 'minmax(0, 768px)',
              lg: 'minmax(0, 768px)',
              md: 'repeat(2, minmax(0, 768px))',
            }}
            gap={{ base: 6, lg: 10 }}
            padding={{ base: 6, lg: 10 }}
          >
            {formattedRestaurants.map(({ conceptName, restaurantPlatformId, heroPicture, logoPicture, tags }) => {
              return (
                <RestaurantCard
                  conceptName={conceptName}
                  heroPicture={heroPicture}
                  key={restaurantPlatformId}
                  logoPicture={logoPicture}
                  restaurantPlatformId={restaurantPlatformId}
                  tags={tags}
                  onClick={(restaurantPlatformId: string) => {
                    seedRestaurantCache(restaurantPlatformId);
                    dataLayer.logEvent('select_concept-click', {
                      brand_label: conceptName,
                      kitchen_label: kitchenLabel,
                      order_channel: 'onsite',
                    });
                  }}
                />
              );
            })}
          </Box>
        </Box>
        <TouchToStartModal
          defaultIsOpen={!!location.state?.startSession}
          onClose={() => {
            startOnSiteSession({ kitchenLabel, terminalUuid });
          }}
        />
      </Layout>
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return (
      <Layout title={<Trans>Our restaurants</Trans>}>
        <ErrorScreen
          button={
            <Button
              colorScheme="black"
              fontWeight={700}
              leftIcon={<MdRefresh />}
              onClick={() => window.location.reload()}
              size="lg"
              textTransform="uppercase"
              variant="solid"
            >
              <Trans>Refresh</Trans>
            </Button>
          }
          text={
            <Heading as="p" size="lg" fontWeight={700} textAlign="center">
              <Trans>Sorry, we’ve encountered an error. Please refresh the page.</Trans>
            </Heading>
          }
          sx={{ height: '100%' }}
        />
      </Layout>
    );
  }

  return (
    <Layout title={<Trans>Our restaurants</Trans>}>
      <Stack alignItems="center" justifyContent="space-evenly" height="100%" paddingBlock={8} paddingX={4}>
        <Stack justifyContent="center" alignItems="center" gap={4} flex={4}>
          <Image src={MoonLogo} width={{ base: 200, md: 300 }} />
          <Text textAlign="center" fontWeight={700} fontSize={{ base: '2xl', md: '4xl' }}>
            <Trans>All our restaurants are closed</Trans>
          </Text>
        </Stack>
        <Image alt="Taster logo" src={TasterLogo} width={{ base: 200, md: 400 }} flex={1} />
      </Stack>
    </Layout>
  );
};
